import { $, Swal, axios } from '../app';

$(function () {
    $('#captchareload').click(function () {
        axios({
            method: 'get',
            url: '/captchareload',
        }).then((a) => {
            $('#captcha').html(a.data.response.captcha);
        }).catch((a) => {
            Swal.fire('Maaf', a.message, 'error');
        });
    });
})
